// import { socketConf } from "../utils/socket";
import * as config from "../utils/config";
import {
  UPDATE_HOSTS,
  UPDATE_GUEST_REQUESTS,
  UPDATE_HOST_REQUESTS,
  UPDATE_NOTIFICATION_PERMISSION,
  SHOW_HIDE_MEETNOW_DASHBOARD,
  SHOW_HIDE_MEETNOW
} from "../actions/types";
// export const myPeerId = uuidv4();

const initialState = {
  hosts: {
    // 5372211572: {
    //   id: "5372211572",
    //   name: "Remi sharp",
    //   availability: false,
    //   busy: false,
    //   profileImage: "/static/images/avatar/1.jpg",
    //   shortBio:
    //     " Hi, I am Remi sharp, the Principal consultant - Technology at centedge. I work with WebRTC and it's ecosystem to help our customers do the right things with video early on while keeping the technology costs under control. How can I help you today?",
    //   primaryAction: true,
    //   secondaryAction: true,
    //   tertiaryAction: true,
    // },
    // 5372211573: {
    //   id: "5372211573",
    //   name: "Oyi Oyi",
    //   availability: false,
    //   busy: false,
    //   profileImage: "/static/images/avatar/2.jpg",
    //   shortBio:
    //     " Hi, I am Oyi Oyi, the Principal consultant - Digital Marketing at centedge. I work to make people aware about the kind of work we are doing at Centedge and how our products and services transform the lead generation as well as overall communication strategies for SMEs. How can I help you today?",
    //   primaryAction: true,
    //   secondaryAction: true,
    //   tertiaryAction: true,
    // },
    // 5372211574: {
    //   id: "5372211574",
    //   name: "Travis Howard",
    //   availability: false,
    //   busy: false,
    //   profileImage: "/static/images/avatar/3.jpg",
    //   shortBio:
    //     " Hi, I am Travis Howard, the Principal consultant - Business development at centedge. I work with SMEs who feel newer ways to meet prospective customers and delight them is truely important to achieve new growth possibilities. I am here to help my team deliver on the promises we make to our customers and help my customers in realising the maximum possible benefits from our products and services. How can I help you today?",
    //   primaryAction: true,
    //   secondaryAction: true,
    //   tertiaryAction: true,
    // },
  },
  guestRequests: {},
  hostRequests: {},
  notificationPermission: false,
  showHideMeetnowDashboard:true,
  showHideMeetnow:true
};

const presenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HOSTS:
      return {
        ...state,
        hosts: action.hosts,
      };
    case UPDATE_GUEST_REQUESTS:
      return {
        ...state,
        guestRequests: action.requests,
      };
    case UPDATE_HOST_REQUESTS:
      return {
        ...state,
        hostRequests: action.requests,
      };
    case UPDATE_NOTIFICATION_PERMISSION:
      return {
        ...state,
        notificationPermission: action.permission,
      };
      case SHOW_HIDE_MEETNOW_DASHBOARD:
      return {
        ...state,
        showHideMeetnowDashboard: action.status,
      };
      case SHOW_HIDE_MEETNOW:
      return {
        ...state,
        showHideMeetnow: action.status,
      };
    default:
      return state;
  }
};

export default presenceReducer;
