import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import axios from "axios";

// import "./styles.css";

const ImageUploader=({images,setImages,buttonText="Click OR Drop here", displayPreview=true})=> {
  
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    // console.log("type of image:",imageList[0])
    // console.log("window.location.host",window.location.origin)
    setImages(imageList);
  };

  return (
    <div>
      <ImageUploading
        // multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={2097152}
        dataURLKey="data_url"
        acceptType={["jpg","png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,errors
        }) => (
          // write your building UI
          
          <div style={{display:"flex", margin:"10px",flexDirection:"column",}}>
            <div>
            <Box
              sx={isDragging ? { color: "red", border:"1px dotted",padding:"12px" } : { padding:"12px",border:"1px dotted" }}
              onClick={onImageUpload}
              {...dragProps}
            >
              {buttonText}
            </Box>
            &nbsp;
            {images.length > 0? 
            <>
            <Button color="secondary" size="small" sx={{margin:"8px 0", height:"32px"}} variant="contained" onClick={onImageRemoveAll}>Remove</Button>
            {/* <Button color="primary" size="small" sx={{margin:"8px 0", height:"32px"}} variant="contained" onClick={uploadImage}>Upload</Button> */}
            </>
            :null}
            </div>
            <div>
            {displayPreview && imageList.map((image, index) => (
              <div key={index} style={{display:"flex",  margin:"10px 0",flexDirection:"column"}}>
                <img src={image.data_url} alt="" width="100" style={{border:"1px dotted"}} />
                {/* <div style={{display:"flex", margin:"10px",flexDirection:"column",}}>
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div> */}
              </div>
            ))}
            </div>
            {errors && <div style={{color:"red"}}>
          {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
          {errors.acceptType && <span>Your selected file type is not allow</span>}
          {errors.maxFileSize && <span>Selected file size exceed Max Size</span>}
          {errors.resolution && <span>Selected file is not match your desired resolution</span>}
        </div>}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default ImageUploader;