import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  Paper,
  IconButton,
  Fab,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserFeedbackSnackbar from "../common/UserFeedbackSnackBar";
import StarBorder from "@mui/icons-material/StarBorder";
import makeStyles from "@mui/styles/makeStyles";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { FileCopyOutlined, QuestionMark } from "@mui/icons-material";
import CopyIcon from "../ConferenceRoom/ParticipantLandingPageIcons/CopyIcon";
import { QRCode } from 'react-qrcode-logo';
import ImageUploader from "../common/ImageUploader";
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import MeetNowInternal from "./MeetNowInternal";
import { useDispatch } from "react-redux";
import * as presenceActions from "../../actions/presenceActions";

const useStyles = makeStyles((theme) => ({
  layout: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    // padding: theme.spacing(2),
    borderRadius: "25px",
    padding: "25px",

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
      width: "85vw",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "85vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      width: "95vw",
    },
  },
}));

const MeetNowDashboard = () => {
  const classes = useStyles();
  const history = useNavigate();
   const dispatch = useDispatch();
  const user = useSelector((state) => state.connection.user);
  const [showAlert, setShowAlert] = useState(false);
  const [userFeedbackSnackBar, setUserFeedbackSnackBar] = useState({});
  const [showHideHelp, setShowHideHelp] = useState(false);
  const qrcodeRef = useRef();
  const [logoSize,setLogoSize] = useState(300);
  const [qrColor, setQrColor]=useState({bgColor:"#ffffff",fgColor:"#000000",eyeColor:"#000000"});
  const [qrStyle, setQrStyle]=useState("square");
  const [logoStyle, setLogoStyle]=useState("square");
  const [eyeRadius, setEyeRadius]=useState(0);
  const [customisation, setCustomisation]=useState(false);
  const [images, setImages] = React.useState([]);
  const orgName = useSelector(
    (state) => state.siteSetting.siteSetting.organizationName
  );
  const logo = useSelector(
    (state) => state.siteSetting.siteSetting.logo
  );
  const orgId = useSelector((state) => state.siteSetting.siteSetting.id) || "1";
  const roomLink = orgName
    ? `https://${window.location.hostname}/meetnow/${user.uuid}/${orgId}/${
        orgName.split(" ")[0]
      }`
    : `https://${window.location.hostname}/meetnow/${user.uuid}/${orgId}/`;

    const showMeetNow = useSelector(state=>state.presence.showHideMeetnowDashboard);

  const copyMeetNowLinkToClipboard = () => {
    // let roomLink = `https://${window.location.hostname}/meetnow/${user.uuid}`;
    navigator.clipboard.writeText(roomLink).then(
      function () {
        setShowAlert(true);
        setUserFeedbackSnackBar({
          severity: "success",
          autoHideDuration: 6000,
          message: "Meetnow link copied successfully.",
        });
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  return (
    <Paper
      className={classes.paper}
      sx={{ position: "relative" }}
      // elevation={4}
    >
      <Button
        //   className={`${classes.iconButton}`}
        style={{
          marginRight: "1rem",
          textTransform: "none",
          fontSize: "17px",
        }}
        onClick={() => history("/dashboard/meetnow")}
        // size="large"
        variant="outlined"
        endIcon={
          <i
            className="fas fa-external-link-alt"
            style={{ fontSize: "1.1rem" }}
          ></i>
        }
      >
        Meetnow
      </Button>
      {showMeetNow?
      <Button variant="outlined" color="secondary" onClick={()=>dispatch(presenceActions.showHideMeetnowDashboard(!showMeetNow))}>{showMeetNow?"Hide":"Show"}</Button>
      :null}
      <Fab
        aria-label="help"
        variant="contained"
        color="secondary"
        onClick={() => history("/dashboard/help?tab=1")}
        size="small"
        // endIcon={<HelpCenterIcon />}
        sx={{ position: "absolute", right: "-5px", top: "-5px" }}
      >
        <QuestionMark />
      </Fab>
      {showMeetNow?
     <MeetNowInternal/> :<Typography variant="body1" align="center">View your Meetnow QRcode or Link <Button variant="outlined"  onClick={()=>dispatch(presenceActions.showHideMeetnowDashboard(!showMeetNow))}>{showMeetNow?"Hide":"View"}</Button></Typography>}
     
    </Paper>
  );
};
export default MeetNowDashboard;
