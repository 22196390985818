import * as config from "../utils/config";

import {
  UPDATE_HOSTS,
  UPDATE_GUEST_REQUESTS,
  UPDATE_HOST_REQUESTS,
  UPDATE_NOTIFICATION_PERMISSION,
  SHOW_HIDE_MEETNOW_DASHBOARD,
  SHOW_HIDE_MEETNOW,
} from "./types";

export const updateHosts = (hosts) => {
  return {
    type: UPDATE_HOSTS,
    hosts,
  };
};

export const updateGuestRequests = (requests) => {
  return {
    type: UPDATE_GUEST_REQUESTS,
    requests,
  };
};

export const updateHostRequests = (requests) => {
  return {
    type: UPDATE_HOST_REQUESTS,
    requests,
  };
};
export const updateNotificationPermission = (status) => {
  return {
    type: UPDATE_NOTIFICATION_PERMISSION,
    permission: status,
  };
};

export const showHideMeetnowDashboard = (status) => {
  return {
    type: SHOW_HIDE_MEETNOW_DASHBOARD,
    status,
  };
};

export const showHideMeetnow = (status) => {
  return {
    type: SHOW_HIDE_MEETNOW,
    status,
  };
};
