import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2632 0H1.89474C0.852632 0 0 0.9 0 2V16H1.89474V2H13.2632V0ZM12.3158 4L18 10V20C18 21.1 17.1474 22 16.1053 22H5.67474C4.63263 22 3.78947 21.1 3.78947 20L3.79895 6C3.79895 4.9 4.64211 4 5.68421 4H12.3158ZM11.3684 11H16.5789L11.3684 5.5V11Z"
        fill="#3F51B5"
      />
    </svg>
  );
};

export default CopyIcon;
