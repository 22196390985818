import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  Paper,
  IconButton,
  Fab,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserFeedbackSnackbar from "../common/UserFeedbackSnackBar";
import StarBorder from "@mui/icons-material/StarBorder";
import makeStyles from "@mui/styles/makeStyles";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { FileCopyOutlined, QuestionMark } from "@mui/icons-material";
import CopyIcon from "../ConferenceRoom/ParticipantLandingPageIcons/CopyIcon";
import { QRCode } from 'react-qrcode-logo';
import ImageUploader from "../common/ImageUploader";
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';

const useStyles = makeStyles((theme) => ({
  layout: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    // padding: theme.spacing(2),
    borderRadius: "25px",
    padding: "25px",

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
      width: "85vw",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "85vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      width: "95vw",
    },
  },
}));

const MeetNowInternal = () => {
  const classes = useStyles();
  const history = useNavigate();
  const user = useSelector((state) => state.connection.user);
  const [showAlert, setShowAlert] = useState(false);
  const [userFeedbackSnackBar, setUserFeedbackSnackBar] = useState({});
  const [showHideHelp, setShowHideHelp] = useState(false);
  const qrcodeRef = useRef();
  const [logoSize,setLogoSize] = useState(150);
  const [qrColor, setQrColor]=useState({bgColor:"#ffffff",fgColor:"#000000",eyeColor:"#000000"});
  const [qrStyle, setQrStyle]=useState("square");
  const [logoStyle, setLogoStyle]=useState("square");
  const [eyeRadius, setEyeRadius]=useState(0);
  const [customisation, setCustomisation]=useState(false);
  const [images, setImages] = React.useState([]);
  const orgName = useSelector(
    (state) => state.siteSetting.siteSetting.organizationName
  );
  const logo = useSelector(
    (state) => state.siteSetting.siteSetting.logo
  );
  const orgId = useSelector((state) => state.siteSetting.siteSetting.id) || "1";
  const roomLink = orgName
    ? `https://${window.location.hostname}/meetnow/${user.uuid}/${orgId}/${
        orgName.split(" ")[0]
      }`
    : `https://${window.location.hostname}/meetnow/${user.uuid}/${orgId}/`;

  const copyMeetNowLinkToClipboard = () => {
    // let roomLink = `https://${window.location.hostname}/meetnow/${user.uuid}`;
    navigator.clipboard.writeText(roomLink).then(
      function () {
        setShowAlert(true);
        setUserFeedbackSnackBar({
          severity: "success",
          autoHideDuration: 6000,
          message: "Meetnow link copied successfully.",
        });
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  return (
   
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "25px",
          // borderBottom: "2px solid #b9b9b9",
        }}
      >
        <Typography variant="body2" gutterBottom>
          Meetnow QR-code that can be used anywhere {" "}
         

        </Typography>
        <Box sx={{display:"flex"}}>
        <Button
            variant={logoSize===150?"contained":"outlined"}
            size="small"
            onClick={() =>
              setLogoSize(150)
            }
            style={{
              margin:"4px",
              textTransform: "none",
            }}
          >
            Small
          </Button>{" "}
          <Button
            variant={logoSize===300?"contained":"outlined"}
            size="small"
            onClick={() =>
              setLogoSize(300)
            }
            style={{
              margin:"4px",
              textTransform: "none",
            }}
          >
            Medium
          </Button>{" "}
          <Button
            variant={logoSize===600?"contained":"outlined"}
            size="small"
            onClick={() =>
              setLogoSize(600)
            }
            style={{
              margin:"4px",
              textTransform: "none",
            }}
          >
            Large
          </Button>{" "}
          <Button
            variant={logoSize===900?"contained":"outlined"}
            size="small"
            onClick={() =>
              setLogoSize(900)
            }
            style={{
              margin:"4px",
              textTransform: "none",
            }}
          >
           X-Large
          </Button>{" "}
          </Box>
        <QRCode ref={qrcodeRef} eyeRadius={eyeRadius} value={roomLink} qrStyle={qrStyle} logoPadding={1} logoImage={logoStyle==="remove"?null:images[0]?.data_url || logo} logoPaddingStyle={logoStyle} enableCORS={true} size={logoSize} bgColor={qrColor.bgColor} fgColor={qrColor.fgColor} eyeColor={qrColor.eyeColor}/>
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <ImageUploader images={images} setImages={setImages} buttonText="Upload logo to QR code" displayPreview={false}/>
        <Button variant="outlined" onClick={()=>setCustomisation(!customisation)} size="small" sx={{height:"48px",marginTop:"12px"}}>Customise Design</Button>
        </Box>
{customisation?
        <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
          <Box m={1}>

<TextField name="eyeRadius" value={eyeRadius} select label="Eye radius" onChange={(event)=>setEyeRadius(event.target.value)} sx={{minWidth:"150px"}}>
<MenuItem value={0} key={0}>0</MenuItem>
  <MenuItem value={5} key={5}>5</MenuItem>
  <MenuItem value={10} key={10}>10</MenuItem>
  <MenuItem value={15} key={15}>15</MenuItem>
  <MenuItem value={20} key={20}>20</MenuItem>
  <MenuItem value={25} key={25}>25</MenuItem>
  <MenuItem value={30} key={30}>30</MenuItem>
  <MenuItem value={40} key={40}>40</MenuItem>
  <MenuItem value={50} key={50}>50</MenuItem>
  
</TextField>
</Box>
          <Box m={1}>

          <TextField name="logoStyle" value={logoStyle} select label="Logo style" onChange={(event)=>setLogoStyle(event.target.value)} sx={{minWidth:"150px"}}>
            <MenuItem value="square" key="square">Square</MenuItem>
            <MenuItem value="circle" key="circle">Circle</MenuItem>
            <MenuItem value="remove" key="remove">Remove Logo</MenuItem>
          </TextField>
          </Box>

          <TextField name="qrStyle" value={qrStyle} select label="QR style" onChange={(event)=>setQrStyle(event.target.value)} sx={{minWidth:"150px",m:"8px"}}>
            <MenuItem value="square" key="square">Square</MenuItem>
            <MenuItem value="dots" key="dots">Dot</MenuItem>
            <MenuItem value="fluid" key="fluid">Fluid</MenuItem>
          </TextField>
          <Box m={1}>
       <Typography variant="body1" gutterBottom>QR color:</Typography> 
         <ColorPicker defaultValue={qrColor.fgColor || "#000000"} onChange={(value)=>setQrColor((qrColor)=>{return {... qrColor,fgColor:value.toHexString()}})}/>
        <TextField sx={{mt:"8px"}} size="small" value={qrColor.fgColor} name="fgColor" onChange={(event)=>setQrColor((qrColor)=>{return {... qrColor,fgColor:event.target.value}})}/>
       </Box>
       <Box m={1}>
       <Typography variant="body1" gutterBottom>Background color:</Typography>  <ColorPicker defaultValue={qrColor.bgColor||"#ffffff"} onChange={(value)=>setQrColor((qrColor)=>{return {... qrColor,bgColor:value.toHexString()}})}/>
       <TextField sx={{mt:"8px"}} size="small" value={qrColor.bgColor} name="bgColor" onChange={(event)=>setQrColor((qrColor)=>{return {... qrColor,bgColor:event.target.value}})}/>
       </Box>
       <Box m={1}>
       <Typography variant="body1" gutterBottom>Eye color:</Typography>  <ColorPicker defaultValue={qrColor.eyeColor || "#000000"} onChange={(value)=>setQrColor((qrColor)=>{return {... qrColor,eyeColor:value.toHexString()}})}/>
       <TextField sx={{mt:"8px"}} size="small" value={qrColor.eyeColor} name="eyeColor" onChange={(event)=>setQrColor((qrColor)=>{return {... qrColor,eyeColor:event.target.value}})}/>
       </Box>
        </Box>
        :null}
        <Button
            variant="contained"
            size="small"
            onClick={() =>
              qrcodeRef.current.download('png',`${orgName}-Meetnow-QRcode`)
            }
            style={{
              marginBottom:"48px",
              marginTop:"24px",
              textTransform: "none",
            }}
          >
            Download
          </Button>{" "}
        

        <Typography variant="body2" gutterBottom>
          Link to embed in websites as a{" "}
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              window.open(roomLink, "_blank", "noopener,noreferrer")
            }
            style={{
              textTransform: "none",
            }}
          >
            Meetnow
          </Button>{" "}
          button:{" "}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{border:"1px solid",padding:"4px 16px",borderRadius:"4px"}}>
          <strong>{roomLink}</strong>

          <Fab
            variant="contained"
            onClick={copyMeetNowLinkToClipboard}
            size="small"
            sx={{ margin: "10px" }}
            color="primary"
          >
          <FileCopyOutlined/>
          </Fab>
        </Typography>
        {showAlert ? (
          <UserFeedbackSnackbar
            severity={userFeedbackSnackBar.severity}
            autoHideDuration={userFeedbackSnackBar.autoHideDuration}
            handleClose={() => setShowAlert(false)}
            open={showAlert}
            message={userFeedbackSnackBar.message}
          />
        ) : null}
      </Box>
     

  );
};
export default MeetNowInternal;
