import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// const arcGreen = "#00ab55";
const brandBlue = "#0A0A49";
const arcGreen = "#141FF5";
const arcOrange = "#f57c00";
const arcRed = "#c2185b";
const arcBlue = "#3f51b5";
const blueLight = "#EAF6F8";
const textHeading = "#2d3748";
const textDefault = "#646e73";
const textWhite = "#fff";
const lightBlue = "#1a90ff";
const headerGrey = "#ced4da"
const brandYellow = "#F5EA14";

export default responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      background: {
        paper: "#fff",
        default: `${blueLight}`
      },
      text: {
        primary: `${textHeading}`,
        default: `${textDefault}`,
        brandBlue:`${brandBlue}`,
        brandYellow:`${brandYellow}`,
        white:`${textWhite}`
      },

      common: {
        green: `${arcGreen}`,
        orange: `${arcOrange}`,
        blue: `${brandBlue}`,
        red: `${arcRed}`,
        lightBlue: `${lightBlue}`,
        headerBlue:`${headerGrey}`
      },
      primary: {
        main: `${arcGreen}`,
      },
      secondary: {
        main: `${arcRed}`,
      },
      // error: {
      //   main: `${arcRed}`,
      // },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h3: {
        fontSize: "3.75rem",
        margin: "0px",
        fontFamily: '"Inter", sans-serif',
        lineHeight: "1.2",
        textAlign: "center",
        fontWeight: "700",
      },
      h4: {
        fontSize: "3rem",
        margin: "0px",
        fontFamily: '"Inter", sans-serif',
        lineHeight: "1",
        textAlign: "center",
        fontWeight: "700",
      },
      h6: {
        fontSize: "1.25rem",
        margin: "0px",
        fontFamily: '"Inter", sans-serif',
        lineHeight: "1.6",
        // textAlign: "center",
        fontWeight: "500",
        color: `${textDefault}`,
      },
      overline: {
        fontSize: "1.5rem",
        margin: "0px",
        fontFamily: '"Inter", sans-serif',
        lineHeight: "1",
        textAlign: "center",
        fontWeight: "100",
      },
      body1: {
        fontSize: "1rem",
        margin: "0px",
        fontFamily: '"Inter", sans-serif',
        lineHeight: "1.5",
        fontWeight: "400",
        // color: `${textDefault}`,
      },
      button: {
        textTransform: "none",
        fontSize: ".9375rem",
        fontWeight: "400",
        maxHeight:"32px"
      },
    },
  })
);
