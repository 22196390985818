import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const initialState = {};

const persistConfig = {
  key: "connection",
  storage,
  whitelist: ["connection"],
  stateReconciler: autoMergeLevel2,
};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);
// let store = createStore(persistedReducer)

// const persistConfig = {
//   key: 'documents',
//   storage: storage,
//   whitelist: ['documents', 'scanner', 'clientDevice']
// };

// const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);

const persistor = persistStore(store);

export { persistor, store };
